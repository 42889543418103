@import url(https://fonts.googleapis.com/css2?family=Blender Pro:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Changa:wght@300;400;500;700&display=swap);
@import url(https://db.onlinewebfonts.com/c/7217c269770e58c0ba17d1f5fa37dc99?family=Overseer);
@import url(https://fonts.cdnfonts.com/css/blender);
body{margin:0;font-family:"Blender Pro",sans-serif !important;font-size:1rem !important;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#121212}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}*{box-sizing:border-box}img{max-width:100%}strong{font-weight:700}::-webkit-scrollbar{width:5px;height:5px}::-webkit-scrollbar-track{background:#000}::-webkit-scrollbar-thumb{background:#959595}::-webkit-scrollbar-thumb:hover{background:#505050}
@font-face {
  font-family: 'Blender Pro Bold';
  src: url(/static/media/BlenderPro-Bold.18483170.woff2) format('woff2'), url(/static/media/BlenderPro-Bold.fc9db575.woff) format('woff');
  /* font-weight: bold; */
  /* font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro Book';
  src: url(/static/media/BlenderPro-Book.92e9aa2f.woff2) format('woff2'), url(/static/media/BlenderPro-Book.ed35621e.woff) format('woff');
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro';
  src: url(/static/media/BlenderPro-Medium.2e841829.woff2) format('woff2'), url(/static/media/BlenderPro-Medium.e6de85f6.woff) format('woff');
  /* font-weight: 600;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro Thin';
  src: url(/static/media/BlenderPro-Thin.53165eef.woff2) format('woff2'), url(/static/media/BlenderPro-Thin.16c88287.woff) format('woff');
  /* font-weight: 900;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro Italic';
  src: url(/static/media/BlenderPro-ThinItalic.46265464.woff2) format('woff2'),
    url(/static/media/BlenderPro-ThinItalic.27817076.woff) format('woff');
  /* font-weight: 300;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro Bold-Italic';
  src: url(/static/media/BlenderPro-BoldItalic.fa206370.woff2) format('woff2'),
    url(/static/media/BlenderPro-BoldItalic.ea31b15d.woff) format('woff');
  /* font-weight: 800;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro';
  src: url(/static/media/BlenderPro-Medium.2e841829.woff2) format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}

@font-face {
  font-family: 'Noto Sans Regular';
  src: url(/static/media/NotoSans-Regular.dd797c0b.ttf) format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}
@font-face {
  font-family: 'Noto Sans';
  src: url(/static/media/NotoSans-Bold.d6c5c03d.ttf) format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}
@font-face {
  font-family: 'Noto Sans Bold Italic';
  src: url(/static/media/NotoSans-BoldItalic.7f54f6dc.ttf) format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}
@font-face {
  font-family: 'Noto Sans Italic';
  src: url(/static/media/NotoSans-Italic.bce6c95c.ttf) format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}

.page-loader{height:100%;display:-webkit-flex;display:flex;-webkit-flex:1 1;flex:1 1;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.page-loader .MuiCircularProgress-colorPrimary{color:#fdfdfd !important}.page-loader.full-height{height:100vh}
.defaultLoading{min-width:22px;color:#fff}
.page-loader{height:100%;display:-webkit-flex;display:flex;-webkit-flex:1 1;flex:1 1;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.page-loader .MuiCircularProgress-colorPrimary{color:#fdfdfd !important}.page-loader.full-height{height:100vh}
