@import url('https://fonts.googleapis.com/css2?family=Blender Pro:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@300;400;500;700&display=swap');
@import url('https://db.onlinewebfonts.com/c/7217c269770e58c0ba17d1f5fa37dc99?family=Overseer');
@import url('https://fonts.cdnfonts.com/css/blender');

body {
  margin: 0;
  font-family: 'Blender Pro', sans-serif !important;
  font-size: 1rem !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 700;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #959595;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #505050;
}
