@font-face {
  font-family: 'Blender Pro Bold';
  src: url('BlenderPro-Bold.woff2') format('woff2'), url('BlenderPro-Bold.woff') format('woff');
  /* font-weight: bold; */
  /* font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro Book';
  src: url('BlenderPro-Book.woff2') format('woff2'), url('BlenderPro-Book.woff') format('woff');
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro';
  src: url('BlenderPro-Medium.woff2') format('woff2'), url('BlenderPro-Medium.woff') format('woff');
  /* font-weight: 600;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro Thin';
  src: url('BlenderPro-Thin.woff2') format('woff2'), url('BlenderPro-Thin.woff') format('woff');
  /* font-weight: 900;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro Italic';
  src: url('BlenderPro-ThinItalic.woff2') format('woff2'),
    url('BlenderPro-ThinItalic.woff') format('woff');
  /* font-weight: 300;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro Bold-Italic';
  src: url('BlenderPro-BoldItalic.woff2') format('woff2'),
    url('BlenderPro-BoldItalic.woff') format('woff');
  /* font-weight: 800;
  font-style: normal; */
}

@font-face {
  font-family: 'Blender Pro';
  src: url('BlenderPro-Medium.woff2') format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}

@font-face {
  font-family: 'Noto Sans Regular';
  src: url('NotoSans-Regular.ttf') format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}
@font-face {
  font-family: 'Noto Sans';
  src: url('NotoSans-Bold.ttf') format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}
@font-face {
  font-family: 'Noto Sans Bold Italic';
  src: url('NotoSans-BoldItalic.ttf') format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}
@font-face {
  font-family: 'Noto Sans Italic';
  src: url('NotoSans-Italic.ttf') format('ttf');
  /* font-weight: 500;
  font-style: normal; */
}
